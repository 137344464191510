import Parallax from '../lib/parallax';
import isTouchDevice from '../lib/detectTouch';

const scene = document.getElementById('paralax');

if (!isTouchDevice) {
  new Parallax(scene, {
    relativeInput: true
  });
}


export default class Sparks {
  constructor(canvasId, config) {
    this.id = 0;
    this.particles = [];
    this.lastFrameTime = null;
    this.cfg = config;
    this.canvas = document.getElementById(canvasId);
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = 0;
    this.canvas.height = 0;

    this.particleLife = 200;
  }
  start() {
    this.loop = setInterval(() => {
      this.addParticle();
      this.drawParticles();
    }, this.cfg.interval);
  }

  init() {
    for(let i = 0; i < 100; i++) {
      this.addParticle();
      this.drawParticles();
    }
  }

  drawParticles() {
    let deltaT;
    let now = +new Date();
    if (this.lastFrameTime === null) {
      deltaT = this.interval;
    } else {
      deltaT = now - this.lastFrameTime;
    }
    this.lastFrameTime = now;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.shadowBlur = this.cfg.shadowBlur;

    this.particles.forEach((v, idx, arr) => {

      if (v.life < (v.maxLife * 2) / 3) {
        this.ctx.fillStyle = this.cfg.particleMiddleColor;
        this.ctx.shadowColor = this.cfg.particleMiddleColor;
      } else if (v.life < (v.maxLife * 1) / 3) {
        this.ctx.fillStyle = this.cfg.particleEndColor;
        this.ctx.shadowColor = this.cfg.particleEndColor;
      } else {
        this.ctx.fillStyle = this.cfg.particleStartColor;
        this.ctx.shadowColor = this.cfg.particleStartColor;
      }
      this.ctx.fillRect(v.x, v.y, v.w, v.h);

      if (v.life > 0) {
        v.directionX =
          v.directionX * (v.x > this.canvas.width || v.x < 0 ? -1 : 1);
        v.directionY =
          v.directionY * (v.y > this.canvas.height || v.y < 0 ? -1 : 1);

        v.x =
          v.x +
          v.directionX * this.cfg.particleSpeed * (deltaT / 1000) +
          Math.cos((v.y + this.getVariation(1)) * (Math.PI / 180));

        v.y =
          v.y + v.directionY * this.cfg.particleSpeed * 1 * (deltaT / 1000);

        if (this.cfg.convection) {
          v.y =
            v.y -
            (v.x > this.canvas.width / 2 - 100 &&
            v.x < this.canvas.width / 2 + 100
              ? 2
              : 0);
        }

        v.life -= deltaT;
      } else {
        arr.splice(idx, 1);
      }
    });
  }

  addParticle() {
    if (this.particles.length < this.cfg.particleLimit) {
      const rectX = Math.floor(Math.random() * this.canvas.width);
      const rectY = Math.floor(this.canvas.height - Math.random() * (this.canvas.height / 10));
      const maxLife = this.particleLife + this.getVariation(this.cfg.particleLifeVariation);

      this.particles.push({
        id: this.id,
        x: rectX,
        y: rectY,
        w: 1,
        h: this.getRandomBetweenTwoNumbers(3, 8),
        life: maxLife,
        maxLife: maxLife,
        directionX: this.getVariation(1),
        directionY: -1
      });
      this.id++;
    }
  }

  destroy() {
    clearInterval(this.loop);
    this.loop = null;
    this.particles = [];
    this.id = 0;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  getVariation(n) {
    return Math.floor(n * (Math.random() > 0.5 ? -1 : 1));
  }

  getRandomBetweenTwoNumbers(min, max) {
    return Math.floor(Math.random()*(max-min+1)+min);
  }
}

const imageLoading = 'is-image-loading';
const isLoaded = 'is-loaded';
const lazyEl = 'js-lazy';

const lazyloadImages = document.querySelectorAll(`.${lazyEl}`);

const imageObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      let image = entry.target;
      image.src = image.dataset.src;
      
      image.classList.remove(lazyEl);
      image.classList.add(isLoaded);
      image.parentNode.parentNode.classList.remove(imageLoading);
      
      imageObserver.unobserve(image);
    }
  });
});

Array.prototype.forEach.call(lazyloadImages, image => {
  imageObserver.observe(image);
});


import { throttle, debounce } from 'throttle-debounce';
const WIN = $(window);
class StickyNav {
  constructor(el) {
    this.el = $('.js-header-sticky');
    this.heroText = $('.js-sticky-hero-text');
    this.stickyText = $('.js-sticky-text');
    this.logo = $('.js-sticky-logo');
    this.logoOffsetTop = this.logo.offset().top;
    this.ww = WIN.innerWidth();
    this.init();
  }

 init() {
  if(!this.el[0]) return;

  this.getHeaderSizes()
  this.scrollInit();
  this.resizeInit();
  this.scrollHandler();
 }

 scrollInit() {
  WIN.on('scroll', throttle(50, this.scrollHandler.bind(this)));
 }

 resizeInit (){
  WIN.on('resize', throttle(100, this.resizeHandler.bind(this)));
 }

 scrollHandler() {
  if(!this.ww > 1125) return;
  const scrollTop = WIN.scrollTop();
    if(scrollTop >= 10){
      this.el.addClass('is-small')
    }else{
      this.el.removeClass('is-small')
    }

    this.getHeaderSizes();
    const textPos = this.heroTextOffsetPos + this.heroTextH - scrollTop - this.el.innerHeight()

    if(textPos < 0){
      this.stickyText.addClass('is-visible');
    }else{
      this.stickyText.removeClass('is-visible');
    }
 }

 resizeHandler(){
  this.ww = WIN.innerWidth();
  this.getHeaderSizes();
 }

 getHeaderSizes(){
    this.heroTextOffsetPos = this.heroText.offset().top
    this.heroTextH = this.heroText.innerHeight();
 }

}

new StickyNav();

/* global $ */

import isTouchDevice from '../lib/detectTouch';
import Sparks from './Sparks';
import {baseUrl, series as rawSeries, layouts} from '../utils/data.json';
import 'slick-carousel';
require('es6-object-assign').polyfill();
const CLASSES = {
  selected: 'is-selected',
  isHide: 'is-hide',
  sharerList: '.js-sharer-list',
  sharerItem: 'js-sharer-item',
  adaptiveWidth: 1125,
  slickInit: '.slick-initialized',
  collageImage: '.js-collage-image',
  collageMovies: '.js-collage-movies',
  collageDownloadImage: '.js-collage-download-image',
  windowWidth: $(window).width(),
  document: $(document)
};

const sparksConfig = {
  interval: 17,
  particleLifeVariation: 1000,
  particleLimit: 100,
  particleLimitMax: 450,
  particleSpeed: 40,
  particleSpeedMax: 100,
  particleStartColor: 'rgba(255, 255, 0, 0.6)',
  particleMiddleColor: 'rgba(255, 174, 0, 0.3)',
  particleEndColor: 'rgba(255, 174, 0, 0.0)',
  shadowBlur: 1,
  convection: true
};

const sparkCanvas = document.getElementById('sparks');

let movieSparks;

if (sparkCanvas) movieSparks = new Sparks('sparks', sparksConfig);
class Movies {
  constructor(el, options) {
    this.el = $(el);
    this.layouts = options.layouts;
    this.series = options.series;
    this.layout = CLASSES.windowWidth > CLASSES.adaptiveWidth ? this.layouts.desktop : this.layouts.mobile;
    this.movieEl = options.movieEl;
    this.sharer = $(options.sharer);

    this.sharedList = '';

    this.translations = {
      more: this.el.data('more'),
      popupDescription: this.el.data('popup-description')
    };

    this.init();
  }

  init() {
    this.createLayout();
    this.onSubmit();
    this.onChange();
    this.onSharerChange();
    this.sharerSlider();
    this.onResize();

    if(!isTouchDevice && sparkCanvas) {
      this.onMovieHover();
      this.onMovieHoverOut();
    }
  }

  createLayout() {
    this.layout.forEach((ids, i) => {
      let counter = 0;
      this.el.append($('<div/>', {
        'class': 'movies__column',
        html: ids.map(id => {
          const movie = this.series[id];
          if (!movie) {
            return '';
          }

          const image = movie.images[i === 1 && !counter ? 1 : counter % 2];
          const aspectRatio = Math.floor((image.height / image.width) * 100);

          counter++;

          return `
            <div class="movies__item">
              <div class="movie is-image-loading js-movie" data-index="${movie.id}" data-id="${movie.id}" style="padding-top: ${aspectRatio}%;">
                <div class="movie__image">
                  <img class="img-responsive js-lazy" data-src="${image.src}"/>
                </div>
                <div class="movie__checkbox">
                  <label>
                    <input value="${movie.id}" name="id[]" type="checkbox"/>
                    <div class="movie__select"></div>
                    <div class="movie__selected"></div>
                    <div class="movie__cover"></div>
                    <div class="movie__sparks"></div>
                  </label>
                </div>
                ${movie.link ? `<a href="${movie.link}" class="movie__link ${this.translations.more !== 'MORE' ? 'rtl' : ''}" target="_blank">${this.translations.more}</a>` : ''}
              </div>
            </div>
          `;
        })
      }));
    });
  }

  onSubmit() {
    this.el.on('submit', e => {
      e.preventDefault();
      return false;
    });

    this.sharer.on('submit', e => {
      e.preventDefault();

      this.sharer.addClass('is-pending');
      $.ajax({
        url: '/generate/',
        dataType: 'json',
        type: 'post',
        data: this.el.serialize(),
        success: response => {
          if (response.success) {
            this.sharedList = response.titles.join('|');
            $(CLASSES.collageMovies).html(this.translations.popupDescription.replace('{movies_list}', response.titles.join(', ')));
            $(CLASSES.collageImage).attr('src', response.images.facebook);
            $(CLASSES.collageDownloadImage).attr('href', response.images.instagram);

            this.shareEvents(response.url);

            setTimeout(() => {
              this.sharer.removeClass('is-pending');
              $('[data-popup="photo-collage"]').fadeIn();
            }, 1000);
          }
        }
      });

      return false;
    });
  }

  onChange() {
    this.el.on('change', () => {
      const moviesChecked = this.el.find('input:checked');

      this[moviesChecked.length === 0 ? 'hideSharer' : 'showSharer']();

      this.createSelectedMovies(moviesChecked.closest(this.movieEl));
    });
  }

  createSelectedMovies(movies) {
    this.removeSharerItem();
    $(this.movieEl).removeClass('is-checked');
    movies.addClass('is-checked');

    if (!isTouchDevice && sparkCanvas) {
      movieSparks.destroy();
    }

    movies.each((i, movie) => {
      const currentMovie = $(movie);
      const id = currentMovie.data('id');

      const {title, images: [, {src}]} = this.series[id];
      const item = this.htmlForShare(id, title, src);

      if (CLASSES.windowWidth > CLASSES.adaptiveWidth) {
        this.renderSharerList(item);
      } else {
        $(CLASSES.sharerList).slick('slickAdd', item);
      }
    });
  }

  renderSharerList(item) {
    $(this.sharer).find(CLASSES.sharerList).append(item);
  }

  htmlForShare(id, title, image) {
    return `
    <div class="sharer__item ${CLASSES.sharerItem}" data-title="${title}" data-id="${id}">
      <div class="sharer__checkbox">
        <label class="sharer__label">
          <input type="checkbox" checked>
          <div class="sharer__text">${title}</div>
          <div class="sharer__selected"></div>
          <div class="sharer__remove"></div>
          <div class="sharer__image" style="background-image: url(${image})"></div>
        </label>
      </div>
    </div>
    `;
  }

  sharerChange(e) {
    const that = $(e.currentTarget);
    const id = that.data('id');
    const index = that.data('slick-index');
    const uncheckMovie = this.el.find(`${this.movieEl}[data-id="${id}"]`).find('input:checkbox');
    const checkCount = this.el.find('input:checked').length;
    uncheckMovie.prop('checked', false);

    if (CLASSES.windowWidth > CLASSES.adaptiveWidth) {
      that.remove();
    } else {
      $(CLASSES.sharerList).slick('slickRemove', index);
    }
    if (checkCount === 1) {
      this.hideSharer();
    }
  }

  movieHover(e) {
    if (!$(e.currentTarget).hasClass('is-checked')) {
      const scrollTop = $(window).scrollTop();
      const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
      movieSparks.canvas.width = width;
      movieSparks.canvas.height = height;
      movieSparks.particleLife = height;
      movieSparks.start();

      $('#sparks').css({
        top: top + scrollTop + 'px',
        left
      });
    }
  }

  onSharerChange() {
    CLASSES.document.on('click', `.${CLASSES.sharerItem}`, this.sharerChange.bind(this));
  }

  onMovieHover() {
    CLASSES.document.on('mouseover', '.js-movie', this.movieHover.bind(this));
  }

  onMovieHoverOut() {
    CLASSES.document.on('mouseout', '.js-movie', () => {
      movieSparks.destroy();
    });
  }

  sharerSlider() {
    if (CLASSES.windowWidth <= CLASSES.adaptiveWidth) {
      $(CLASSES.sharerList).not(CLASSES.slickInit).slick({
        dots: false,
        arrows: true,
        infinite: false,
        variableWidth: true,
        slidesToShow: 5,
        rows: 0,
        responsive: [{
          breakpoint: CLASSES.adaptiveWidth,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 2
          }
        }]
      });
    } else {
      if ($(CLASSES.sharerList + CLASSES.slickInit).length >= 1) {
        $(CLASSES.sharerList).slick('unslick');
      }
    }
  }

  shareEvents(url) {
    $('.js-share-to-fb')
      .off('click')
      .on('click', e => {
        e.preventDefault();
        trackEvent('Share on Facebook', 'Social Sharing', this.sharedList);
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), 'sharer', `height=450, width=550, top=${($(window).height() / 2 - 275)}, left=${($(window).width() / 2 - 225)}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
      });
    $('.js-share-to-twitter')
      .off('click')
      .on('click', e => {
        e.preventDefault();

        console.log('loh')
        trackEvent('Share on Twitter', 'Social Sharing', this.sharedList);
        window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(url) + "&text=" + encodeURIComponent("هذه مسلسلاتي المفضلة في رمضان. و انت؟"), 'sharer', `height=450, width=550, top=${($(window).height() / 2 - 275)}, left=${($(window).width() / 2 - 225)}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
      });

    $('.js-share-to-ig')
      .off('click')
      .on('click', () => {
        trackEvent('Share on Instagram', 'Social Sharing', this.sharedList);
      }
    );
  }

  removeSharerItem() {
    $(`.${CLASSES.sharerItem}`).remove();
  }

  showSharer() {
    this.sharer.removeClass(CLASSES.isHide);
  }

  hideSharer() {
    this.sharer.addClass(CLASSES.isHide);
  }

  onResize() {
    $(window).resize(() => {
      this.sharerSlider();
    });
  }
}

new Movies('.js-movies', {
  layouts,
  series: Object.keys(rawSeries).reduce((series, key) => {
    const [id, title, portraitImage, portraitImageWidth, portraitImageHeight, landscapeImage, landscapeImageWidth, landscapeImageHeight, link] = rawSeries[key];

    return Object.assign(series, {
      [key]: {
        id,
        title,
        images: [{
          src: `${baseUrl}${portraitImage}`,
          width: portraitImageWidth,
          height: portraitImageHeight
        }, {
          src: `${baseUrl}${landscapeImage}`,
          width: landscapeImageWidth,
          height: landscapeImageHeight
        }],
        link
      }
    });
  }, {}),
  movieEl: '.js-movie',
  sharer: '.js-sharer'
});


import 'intersection-observer';

import isTouchDevice from './lib/detectTouch';

if (!isTouchDevice) $('body').addClass('no-touch');
import './modules/_analitics';
import './modules/Movies';
import './modules/Sticky-nav';
import './modules/_lazy-loading';
import './modules/_popup';
import './modules/_paralax';

const showHandlePopup = $('.js-show-popup');
const hideHandlePopup = $('.js-hide-popup');

showHandlePopup.on('click', e => {
  const that = $(e.currentTarget);
  const target = that.data('target');

  $(`[data-popup="${target}"]`).addClass('is-show');
  
});

hideHandlePopup.on('click', e => {
  const that = $(e.currentTarget);
  const parent = that.closest('.js-popup');
  
  parent.fadeOut();
});
